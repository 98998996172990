.section-desktop {
	width: 1200px;
	height: auto;
}
.section-mobile {
	min-width: 320px;
	max-width: 480px;
	height: auto;
	margin: 0 auto;
}
